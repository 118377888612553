import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AddEditViewMode, addEditViewNodes, InstitutionAddEditViewParams } from './institution-add-edit-view';
import { TenantsService } from '../../../services/tenants.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { routePath } from '../../app-routing';
import {
  ConfirmationDialogComponent,
  IdFormatterService,
  NotificationService,
  Severity,
} from '@digital-factory/ds-common-ui';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { EMAIL_REGEX, NAME_REGEX, PHONE_NUMBER_REGEX } from '../../../info-model/constants/form-validators.constants';
import { Application } from '../../../info-model/application';
import { Status, StatusDisplay, StatusKey } from '../../../info-model/enums/status';
import { Login, LoginDisplay, LoginKey } from '../../../info-model/enums/login';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationsService } from '../../../services/applications.service';
import {
  Tenant,
  TenantCreateUpdate,
  tenantDateFormats,
  tenantLanguages,
  TenantLocation,
  tenantNumberFormats,
  tenantTimeFormats,
} from '../../../info-model/tenant';

type MyForm = Omit<Tenant, 'links' | 'locations' | 'tenant_id' | 'tenant_type'> & TenantLocation;
type CopyType = Extract<keyof MyForm, 'client_id' | 'gateway_key' | 'client_secret'>;

@Component({
  templateUrl: './institution-add-edit-view.component.html',
  styleUrl: './institution-add-edit-view.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InstitutionAddEditViewComponent extends BaseFormDirective<MyForm> implements OnInit {
  readonly applicationsService = inject(ApplicationsService);
  application!: Readonly<Application>;
  clientSecretVisible = false;
  readonly copyTooltip = 'Copy to the clipboard';
  readonly idFormatterService = inject(IdFormatterService);
  readonly loginDisplay = LoginDisplay;
  readonly loginDisplayKeys = Object.keys(LoginDisplay) as LoginKey[];
  readonly statusDisplay = StatusDisplay;
  readonly statusDisplayKeys = Object.keys(StatusDisplay) as StatusKey[];
  readonly error = {
    required: 'Required Field',
  };
  mode!: AddEditViewMode;
  myForm!: MyForm;
  readonly routePath = routePath;
  readonly tenantDateFormats = tenantDateFormats;
  readonly tenantLanguages = tenantLanguages;
  readonly tenantNumberFormats = tenantNumberFormats;
  readonly tenantTimeFormats = tenantTimeFormats;

  title!: string;

  private readonly dialog = inject(MatDialog);
  private readonly institutionsService = inject(TenantsService);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private tenant!: Tenant;

  constructor() {
    super();
  }

  actionClipboard(copyType: CopyType) {
    const { notificationService, tenant } = this;
    const value = tenant[copyType];
    let name: string;

    switch (copyType) {
      case 'client_id':
        name = 'Client ID';
        break;
      case 'client_secret':
        name = 'Client Secret';
        break;
      case 'gateway_key':
        name = 'Gateway Key';
        break;
    }

    navigator.clipboard.writeText(value);
    notificationService.showMessage(`Copied to the clipboard: ${name}`, Severity.Success);
  }

  actionSave() {
    const { mode, institutionsService, rawValue, router, notificationService, form, tenant } = this;
    const {
      application,
      contact_email,
      contact_firstname,
      contact_lastname,
      contact_phone,
      country,
      customer_id,
      date_format,
      gateway_key,
      language,
      license_expiration_date,
      license_options,
      license_renewal_contact,
      location_name,
      login_type,
      number_format,
      region,
      regulatory_entity,
      status,
      tenant_name,
      tier,
      time_format,
      time_zone,
    } = rawValue;
    const nav = () => {
      const message = `The Institution "${tenant_name}" has been ` + (mode === 'edit' ? 'updated' : 'added');

      form.markAsPristine();
      notificationService.showMessage(message, Severity.Success);
      router.navigate([routePath.institutions, { tenant_name }]);
    };
    const createUpdate: TenantCreateUpdate = {
      application,
      contact_email,
      contact_firstname,
      contact_lastname,
      contact_phone,
      country,
      customer_id,
      date_format,
      gateway_key,
      language,
      login_type,
      number_format,
      region,
      regulatory_entity,
      status,
      tenant_name,
      time_format,
      time_zone,
      locations: [{ license_expiration_date, license_options, license_renewal_contact, location_name, tier }],
    };

    if (mode === 'add') {
      institutionsService.createTenant$(createUpdate).subscribe(() => nav());
    } else {
      // TODO remove when tenant_id is no longer needed https://clinicalsoftware.atlassian.net/browse/ST11-415
      (createUpdate as unknown as Record<string, string>)['tenant_id'] = tenant.tenant_id;
      institutionsService.updateTenant$(createUpdate, tenant.links).subscribe(() => nav());
    }
  }

  canDeactivate(): boolean | Observable<boolean> {
    const { form, dialog } = this;

    if (form.dirty) {
      return dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: 'Leave Institution',
            message: 'Are you sure you want to leave this institution without saving your edits?',
            cancelButtonText: 'Cancel',
            okButtonText: 'Yes',
            okButtonId: 'Yes',
          },
        })
        .afterClosed();
    }

    return true;
  }
  hasError(control: FormControl, errorName: string): boolean {
    const state = errorName === 'invalid' ? control.invalid : control.hasError(errorName);
    return state && control.touched;
  }

  ngOnInit() {
    const { route, router, destroyRef, applicationsService } = this;

    applicationsService.initialize().subscribe(() => {
      route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((p: Params) => {
        const params = p as InstitutionAddEditViewParams;

        if (addEditViewNodes.includes(params.mode)) {
          this.mode = params.mode;
          switch (this.mode) {
            case 'add':
              this.title = 'Add Institution';
              break;
            case 'edit':
              this.title = 'Update Institution -';
              break;
            case 'view':
              this.title = 'View Institution (Read Only) -';
              break;
          }

          if (this.mode === 'add') {
            this.initializeTenant();
          } else {
            this.getTenant(params.id);
          }
        } else {
          router.navigate([routePath.error, '404']);
        }
      });
    });
  }

  private getTenant(tenantId: string) {
    const { institutionsService, applicationsService } = this;

    institutionsService.getTenant$(tenantId).subscribe((tenant: Tenant) => {
      this.tenant = tenant;
      this.myForm = Object.assign({}, tenant, tenant.locations[0]);
      this.application = applicationsService.get(tenant.application);

      this.initializeForm();
    });
  }

  private initializeTenant() {
    const {
      applicationsService: { applications },
    } = this;

    this.myForm = {
      client_id: '',
      client_secret: '',
      country: '',
      date_format: '',
      language: '',
      number_format: '',
      region: '',
      time_format: '',
      time_zone: '',
      application: '',
      contact_email: '',
      contact_firstname: '',
      contact_lastname: '',
      contact_phone: '',
      customer_id: '',
      gateway_key: '',
      license_expiration_date: '',
      license_options: [''],
      license_renewal_contact: '',
      location_name: '',
      login_type: Login.LOCAL,
      regulatory_entity: '',
      status: Status.ACTIVE,
      tenant_name: '',
      tier: '',
    };

    if (applications.length === 1) {
      this.application = applications[0];

      const { myForm } = this;
      const { name, regulatory_entities, tiers } = this.application;

      myForm.application = name.key;

      if (regulatory_entities.length === 1) {
        myForm.regulatory_entity = regulatory_entities[0].key;
      }

      if (tiers.length === 1) {
        myForm.tier = tiers[0].key;
      }
    }

    this.initializeForm();
  }

  private initializeForm() {
    const { fb, myForm, mode, destroyRef, applicationsService } = this;
    const {
      application,
      client_id,
      contact_email,
      contact_firstname,
      contact_lastname,
      contact_phone,
      client_secret,
      country,
      customer_id,
      date_format,
      gateway_key,
      language,
      license_expiration_date,
      license_options,
      license_renewal_contact,
      location_name,
      login_type,
      number_format,
      region,
      regulatory_entity,
      status,
      tenant_name,
      tier,
      time_format,
      time_zone,
    } = myForm;
    const { required } = Validators;
    const disabled = mode == 'view';
    const regex: Record<'email' | 'name' | 'phone', ValidatorFn> = {
      email: Validators.pattern(EMAIL_REGEX),
      name: Validators.pattern(NAME_REGEX),
      phone: Validators.pattern(PHONE_NUMBER_REGEX),
    };
    const record: Record<keyof MyForm, unknown> = {
      application: [{ value: application, disabled: mode !== 'add' }, required],
      client_id: [{ value: client_id || '', disabled: true }],
      client_secret: [{ value: client_secret || '', disabled: true }],
      contact_email: [{ value: contact_email, disabled }, [required, regex.email]],
      contact_firstname: [{ value: contact_firstname, disabled }, [required, regex.name]],
      contact_lastname: [{ value: contact_lastname, disabled }, [required, regex.name]],
      contact_phone: [{ value: contact_phone, disabled }, [regex.phone]],
      country: [{ value: country, disabled }],
      customer_id: [{ value: customer_id, disabled }, [required, regex.name]],
      date_format: [{ value: date_format, disabled }, required],
      gateway_key: [{ value: gateway_key || '', disabled: true }],
      language: [{ value: language, disabled }],
      license_expiration_date: [
        { value: license_expiration_date.substring(0, '2000-01-01'.length), disabled },
        required,
      ],
      license_options: [{ value: license_options, disabled }],
      license_renewal_contact: [{ value: license_renewal_contact, disabled }, regex.email],
      location_name: [{ value: location_name, disabled }],
      login_type: [{ value: login_type, disabled }, required],
      number_format: [{ value: number_format, disabled }, required],
      region: [{ value: region, disabled }],
      regulatory_entity: [{ value: regulatory_entity, disabled }],
      status: [{ value: status, disabled }, required],
      tenant_name: [{ value: tenant_name, disabled }, [required, regex.name]],
      tier: [{ value: tier, disabled }, required],
      time_format: [{ value: time_format, disabled }, required],
      time_zone: [{ value: time_zone, disabled }],
    };

    this.form = fb.nonNullable.group(record); // enforces no fields are null

    if (mode === 'view') {
      this.form.disable();
    } else if (mode === 'add') {
      const { application, regulatory_entity } = this.controls;

      application.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((application) => {
        this.application = applicationsService.get(application);

        // Not all applications require a regulatory entity. Set required if it requires one.
        if (this.application.regulatory_entities.length > 0) {
          regulatory_entity.setValidators(required);
        } else {
          regulatory_entity.clearValidators();
        }

        regulatory_entity.setValue('');
        regulatory_entity.updateValueAndValidity();
      });
    }
  }
}
