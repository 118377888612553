import { Link } from './link';
import { Login } from './enums/login';
import { Status } from './enums/status';
import { KeyValue } from '@angular/common';

export interface Tenant {
  application: string;
  client_id: string;
  client_secret: string;
  contact_email: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_phone: string;
  country: string;
  customer_id: string;
  date_format: string;
  gateway_key: string;
  language: string;
  links: Link[];
  locations: TenantLocation[];
  login_type: Login;
  number_format: string;
  region: string;
  regulatory_entity: string;
  status: Status;
  tenant_id: string;
  tenant_name: string;
  tenant_type?: string; // used only by the b/e
  time_format: string;
  time_zone: string;
}

export interface TenantLocation {
  license_expiration_date: string;
  license_options: string[];
  license_renewal_contact: string;
  location_name: string;
  tier: string;
}

/** used for POST/PUT  */
export type TenantCreateUpdate = Omit<Tenant, 'client_id' | 'client_secret' | 'tenant_type' | 'links' | 'tenant_id'>;

export const tenantNumberFormats: KeyValue<'decimal' | 'comma', string>[] = [
  { key: 'decimal', value: '1,234.56' },
  { key: 'comma', value: '1.234,56' },
];

export const tenantTimeFormats: KeyValue<'12' | '24', string>[] = [
  { key: '12', value: '12 hour' },
  { key: '24', value: '24 hour' },
];

export const tenantLanguages: KeyValue<string, string>[] = [
  { value: 'Arabic', key: 'ar' },
  { value: 'Bulgarian', key: 'bg' },
  { value: 'Chinese Simplified', key: 'zh-cn' },
  { value: 'Chinese Traditional', key: 'zh-hk' },
  { value: 'Croatian', key: 'hr' },
  { value: 'Czech', key: 'cs' },
  { value: 'Danish', key: 'da' },
  { value: 'Dutch', key: 'nl' },
  { value: 'English', key: 'en' },
  { value: 'Finnish', key: 'fi' },
  { value: 'French', key: 'fr' },
  { value: 'Greek', key: 'el' },
  { value: 'Hungarian', key: 'hu' },
  { value: 'Italian', key: 'it' },
  { value: 'Japanese', key: 'ja' },
  { value: 'Korean', key: 'ko' },
  { value: 'Latvian', key: 'lv' },
  { value: 'Lithuanian', key: 'lt' },
  { value: 'Norwegian', key: 'no' },
  { value: 'Polish', key: 'pl' },
  { value: 'Portuguese', key: 'pt' },
  { value: 'Portuguese (Brazil)', key: 'pt-br' },
  { value: 'Romanian', key: 'ro' },
  { value: 'Russian', key: 'ru' },
  { value: 'Serbian', key: 'sr' },
  { value: 'Slovakian', key: 'sk' },
  { value: 'Slovenian', key: 'sl' },
  { value: 'Spanish', key: 'es' },
  { value: 'Swedish', key: 'sv' },
  { value: 'Turkish', key: 'tr' },
];

export const tenantDateFormats: string[] = ['YYYY-MM-DD', 'MM/DD/YYYY', 'DD.MM.YYYY'];
