import { Location } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationDialogComponent,
  NotificationService,
  Severity,
  TableComponent,
} from '@digital-factory/ds-common-ui';
import { TableConfiguration } from '@digital-factory/ds-common-ui/lib/info-model/table-configuration';
import { EMPTY, finalize, first, map, Observable, of } from 'rxjs';

import { Login, LoginDisplay } from 'src/info-model/enums/login';
import { StatusDisplay } from 'src/info-model/enums/status';
import { InstitutionsService, Tenant } from 'src/services/institutions.service';
import { routePath } from '../app-routing';
import { InstitutionAddEditViewParams } from './institution-add-edit-view/institution-add-edit-view';
import { UsersService } from '../../services/users.service';
import { ApplicationsService } from '../../services/applications.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseSubscriptionsDirective } from '../shared/base-subscriptions/base-subscriptions.directive';
import { GenericRecord } from '../../info-model/generic';

@Component({
  selector: 'app-institution-management',
  templateUrl: './institution-management.component.html',
  styleUrls: ['./institution-management.component.scss'],
})
export class InstitutionManagementComponent extends BaseSubscriptionsDirective implements OnInit, AfterViewInit {
  @ViewChild(TableComponent) table!: TableComponent;

  config: TableConfiguration = {
    name: 'InstitutionManagement-InstitutionsTable',
    pageSize: 10,
    dataSource: EMPTY,
    columns: [],
    actions: [],
    emptyMessage: 'No institutions match the search criteria',
  };

  isEmpty = true;
  isLoading = true;
  tenant_name!: string;

  private readonly applicationsService = inject(ApplicationsService);
  private readonly institutionsService = inject(InstitutionsService);
  private readonly dialog = inject(MatDialog);
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly notificationService = inject(NotificationService);
  private readonly usersService = inject(UsersService);

  actionAddInstitution() {
    const params: Partial<InstitutionAddEditViewParams> = {
      mode: 'add',
    };
    this.router.navigate([routePath.institutions, '0', params]);
  }

  //Use ngAfterViewInit to avoid error of 'Expression has changed after it was checked' in unit tests
  ngAfterViewInit(): void {
    const { applicationsService } = this;

    applicationsService.initialize().subscribe(() => this.initialize());
  }

  ngOnInit() {
    const { destroyRef, route, location } = this;

    route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((p) => {
      const tenant_name = p['tenant_name'];

      if (typeof tenant_name === 'string') {
        location.replaceState(`/${routePath.institutions}`);
        // this.tenant_name = tenant_name.trim(); TODO put in when ticket created
      }
    });
  }

  delete(tenant: Tenant) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Institution',
        message: `Are you sure you want to delete ${tenant.tenant_name}?`,
        cancelButtonText: 'Cancel',
        okButtonText: 'Delete',
        okButtonId: 'Delete',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (tenant) {
          this.institutionsService
            .deleteTenant$(tenant.tenant_id ?? '', tenant.tenant_name)
            .pipe(first())
            .subscribe(() => {
              this.refreshTable();
              this.notificationService.showMessage('The institution was deleted.', Severity.Success);
            });
        }
      }
    });
  }

  refreshTable() {
    this.table.selected = null;
    this.config = {
      ...this.config,
      dataSource: this.getData(true),
    };
  }

  private initialize() {
    const { router, usersService, applicationsService } = this;
    const toTenant = (element: unknown) => element as Tenant;

    this.config = {
      ...this.config,
      dataSource: this.getData(true),
      columns: [
        {
          key: 'tenant_name',
          label: 'Institution Name',
          isSortable: true,
        },
        {
          key: 'customer_id',
          label: 'Customer ID',
        },
        {
          key: 'contact_email',
          label: 'E-mail',
        },
        {
          key: 'support_email',
          label: 'IT E-mail',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'application',
          label: 'Application',
          valueMap: applicationsService.namesRecord,
        },
        {
          key: 'status',
          label: 'Status',
          valueMap: StatusDisplay,
        },
        {
          key: 'login_type',
          label: 'Login',
          valueMap: LoginDisplay,
        },
        {
          key: 'license_type',
          label: 'Tier',
        },
      ],
      actions: [
        {
          label: 'View Institution',
          icon: 'icon-eye',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              const params: Partial<InstitutionAddEditViewParams> = {
                mode: 'view',
              };
              router.navigate([routePath.institutions, toTenant(element).tenant_id, params]);
            }
          },
        },
        {
          label: 'Update Institution',
          icon: 'icon-pencil',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              const params: Partial<InstitutionAddEditViewParams> = {
                mode: 'edit',
              };
              const row = element as unknown as Tenant;
              router.navigate([routePath.institutions, row.tenant_id, params]);
            }
          },
        },
        {
          label: 'Manage Users',
          icon: 'icon-users',
          isDisabled: (element) => element['login_type'] === Login.SSO,
          callback: (element) => {
            if (element) {
              const row = element as unknown as Tenant;
              router.navigate(usersService.navigate(row.tenant_id));
            }
          },
        },
        {
          label: 'Delete Institution',
          icon: 'icon-trash',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              this.delete(toTenant(element));
            }
          },
        },
      ],
    };
    this.config.defaultSortColumn = this.config.columns[0].key;
  }

  private mapTenantsToRows = (tenants: Tenant[]): GenericRecord[] => {
    const { applicationsService } = this;

    this.isEmpty = tenants.length === 0;
    this.isLoading = false;

    for (const tenant of tenants) {
      const application = applicationsService.get(tenant.application);
      const tier = application.tiers.find((tier) => tier.key === tenant.license_type);

      if (tier) {
        tenant.license_type = tier.value;
      }
    }

    return tenants as unknown as GenericRecord[];
  };

  private onError() {
    this.config = {
      ...this.config,
      dataSource: this.getData(false),
    };
  }

  private getData(shouldLoadData: boolean): Observable<GenericRecord[]> {
    if (shouldLoadData) {
      let rx: Tenant[] = [];

      return this.institutionsService
        .getTenants$(() => this.onError())
        .pipe(
          map((rows) => {
            rx = rows;
            return this.mapTenantsToRows(rows);
          }),
          finalize(() => {
            const { tenant_name } = this;

            if (tenant_name) {
              this.tenant_name = '';
              setTimeout(() => {
                const { table } = this;
                if (table) {
                  const row = rx.find((r) => r.tenant_name === tenant_name);

                  if (row) {
                    table.filterControl.setValue(row.tenant_name);
                  }
                }
              }, 500);
            }
          }),
        );
    } else {
      this.isEmpty = true;
      this.isLoading = false;
      return of([]);
    }
  }
}
