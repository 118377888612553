import { inject, Injectable } from '@angular/core';
import { Application, ServerApplication } from '../info-model/application';
import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { NotificationService, Severity } from '@digital-factory/ds-common-ui';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  private readonly httpClient = inject(HttpClient);
  private readonly notificationService = inject(NotificationService);
  private readonly urlService = inject(UrlService);
  private _applications?: Application[];

  get applications(): Readonly<Readonly<Application>[]> {
    if (!this._applications) {
      throw new Error(`Call initialize() first`);
    }

    return this._applications;
  }

  get names(): Readonly<KeyValue<string, string>>[] {
    return this.applications.map((a) => a.name);
  }

  get namesRecord(): Record<string, string> {
    return this.kvsToRecord(this.applications.map((a) => a.name));
  }

  get(name: string): Readonly<Application> {
    const result = this.applications.find((application) => application.name.key === name);

    if (!result) {
      throw new Error(`Application not found: ${name}`);
    }

    return result;
  }

  /**
   * This *MUST* be called *BEFORE* accessing other methods. this.init() should be called  once.
   */
  initialize(): Observable<boolean> {
    if (this._applications) {
      return of(true);
    } else {
      return this.init();
    }
  }

  kvsToRecord(kvs: KeyValue<string, string>[]): Record<string, string> {
    const result: Record<string, string> = {};

    for (const kv of kvs) {
      result[kv.key] = kv.value;
    }

    return result;
  }

  /**
   * Called only via this.initialize. Should only be called once
   */
  private init(): Observable<boolean> {
    const { httpClient, urlService, notificationService } = this;
    const toKVs = (order: string[], record: Record<string, string>) =>
      order.map((key) => ({
        key,
        value: record[key],
      }));

    return httpClient.get<ServerApplication[]>(urlService.getApiUrl('appconfig')).pipe(
      catchError((error) => {
        console.error('appConfig failed', error);
        notificationService.showMessage('appConfig endpoint failed, see console tab', Severity.Error);
        return throwError(() => error);
      }),
      map((recs: ServerApplication[]) => {
        return recs.map((rec: ServerApplication) => {
          const {
            id,
            multiple_locations,
            name,
            options,
            options_order,
            regulatory_entities,
            regulatory_entities_order,
            tiers,
            tiers_order,
            user_roles,
            user_roles_order,
          } = rec;

          return {
            id,
            name: toKVs(Object.keys(name), name)[0],
            multiple_locations,
            options: toKVs(options_order, options),
            regulatory_entities: toKVs(regulatory_entities_order, regulatory_entities),
            tiers: toKVs(tiers_order, tiers),
            user_roles: toKVs(user_roles_order, user_roles),
          };
        });
      }),
      map((recs: Application[]) => {
        this._applications = recs.sort((a, b) => a.id - b.id);
        return true;
      }),
    );
  }
}
