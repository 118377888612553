import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AddEditViewMode, addEditViewNodes, InstitutionAddEditViewParams } from './institution-add-edit-view';
import { InstitutionsService, Tenant } from '../../../services/institutions.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { routePath } from '../../app-routing';
import {
  ConfirmationDialogComponent,
  IdFormatterService,
  NotificationService,
  Severity,
} from '@digital-factory/ds-common-ui';
import { FormControl, Validators } from '@angular/forms';
import { EMAIL_REGEX, NAME_REGEX, PHONE_NUMBER_REGEX } from '../../../info-model/constants/form-validators.constants';
import { Application } from '../../../info-model/application';
import { Status, StatusDisplay, StatusKey } from '../../../info-model/enums/status';
import { Login, LoginDisplay, LoginKey } from '../../../info-model/enums/login';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationsService } from '../../../services/applications.service';

@Component({
  templateUrl: './institution-add-edit-view.component.html',
  styleUrl: './institution-add-edit-view.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InstitutionAddEditViewComponent extends BaseFormDirective<Tenant> implements OnInit {
  readonly applicationsService = inject(ApplicationsService);
  application!: Readonly<Application>;
  readonly idFormatterService = inject(IdFormatterService);
  readonly loginDisplay = LoginDisplay;
  readonly loginDisplayKeys = Object.keys(LoginDisplay) as LoginKey[];
  readonly statusDisplay = StatusDisplay;
  readonly statusDisplayKeys = Object.keys(StatusDisplay) as StatusKey[];
  readonly error = {
    required: 'Required Field',
  };
  mode!: AddEditViewMode;
  readonly routePath = routePath;
  tenant!: Tenant;
  title!: string;

  private readonly dialog = inject(MatDialog);
  private readonly institutionsService = inject(InstitutionsService);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  constructor() {
    super();
  }

  actionSave() {
    const { mode, institutionsService, formValue, router, notificationService, form, tenant } = this;
    const nav = () => {
      const message = `The Institution "${formValue.tenant_name}" has been ` + (mode === 'edit' ? 'updated' : 'added');

      form.markAsPristine();
      notificationService.showMessage(message, Severity.Success);
      router.navigate([routePath.institutions, { tenant_name: formValue.tenant_name }]);
    };

    if (mode === 'add') {
      institutionsService.createTenant$(formValue).subscribe(() => nav());
    } else {
      formValue.tenant_id = tenant.tenant_id; // Disabling the formValue causes the id npt to be saved
      formValue.links = tenant.links;
      institutionsService.updateTenant$(formValue).subscribe(() => nav());
    }
  }

  canDeactivate(): boolean | Observable<boolean> {
    const { form, dialog } = this;

    if (form.dirty) {
      return dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: 'Leave Institution',
            message: 'Are you sure you want to leave this institution without saving your edits?',
            cancelButtonText: 'Cancel',
            okButtonText: 'Yes',
            okButtonId: 'Yes',
          },
        })
        .afterClosed();
    }

    return true;
  }

  hasError(control: FormControl, errorName: string): boolean {
    const state = errorName === 'invalid' ? control.invalid : control.hasError(errorName);
    return state && control.touched;
  }

  ngOnInit() {
    const { route, router, destroyRef, applicationsService } = this;

    applicationsService.initialize().subscribe(() => {
      route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((p: Params) => {
        const params = p as InstitutionAddEditViewParams;

        if (addEditViewNodes.includes(params.mode)) {
          this.mode = params.mode;
          switch (this.mode) {
            case 'add':
              this.title = 'Add Institution';
              break;
            case 'edit':
              this.title = 'Edit Institution';
              break;
            case 'view':
              this.title = 'View Institution (Read Only)';
              break;
          }

          if (this.mode === 'add') {
            this.initializeTenant();
          } else {
            this.getTenant(params.id);
          }
        } else {
          router.navigate([routePath.error, '404']);
        }
      });
    });
  }

  private getTenant(tenantId: string) {
    const { institutionsService, applicationsService } = this;

    institutionsService.getTenant$(tenantId).subscribe((tenant: Tenant) => {
      this.tenant = tenant as Tenant;
      this.application = applicationsService.get(tenant.application);

      this.initializeForm();
    });
  }

  private initializeTenant() {
    const {
      applicationsService: { applications },
    } = this;

    this.tenant = {
      tenant_id: '',
      tenant_name: '',
      customer_id: '',
      contact_email: '',
      support_email: '',
      phone: '',
      application: '',
      status: Status.ACTIVE,
      login_type: Login.LOCAL,
      license_type: '',
      links: [],
      regulatory_entity: '',
    };

    if (applications.length === 1) {
      this.application = applications[0];

      const { tenant } = this;
      const { name, regulatory_entities } = this.application;

      tenant.application = name.key;

      if (regulatory_entities.length === 1) {
        tenant.regulatory_entity = regulatory_entities[0].key;
      }
    }

    this.initializeForm();
  }

  private initializeForm() {
    const { fb, tenant, mode, destroyRef, applicationsService } = this;
    const disabled = mode === 'view';
    const { required } = Validators;
    const record: Record<Exclude<keyof Tenant, 'links'>, unknown> = {
      application: [{ value: tenant.application, disabled }, required],
      contact_email: [{ value: tenant.contact_email, disabled }, [required, Validators.pattern(EMAIL_REGEX)]],
      customer_id: [{ value: tenant.customer_id, disabled }, [required, Validators.pattern(NAME_REGEX)]],
      license_type: [{ value: tenant.license_type, disabled }, required],
      login_type: [{ value: tenant.login_type, disabled }, required],
      phone: [{ value: tenant.phone, disabled }, [Validators.pattern(PHONE_NUMBER_REGEX)]],
      status: [{ value: tenant.status, disabled }, required],
      support_email: [{ value: tenant.support_email, disabled }, [Validators.pattern(EMAIL_REGEX)]],
      tenant_id: [{ value: tenant.tenant_id, disabled: true }],
      tenant_name: [{ value: tenant.tenant_name, disabled }, [required, Validators.pattern(NAME_REGEX)]],
      regulatory_entity: [{ value: tenant.regulatory_entity, disabled }, required],
    };
    // Guarantee that no fields are null
    this.form = fb.nonNullable.group(record);

    this.resetValue = this.formValue;

    if (mode !== 'view') {
      this.controls.application.valueChanges
        .pipe(takeUntilDestroyed(destroyRef))
        .subscribe((application) => (this.application = applicationsService.get(application)));
    }
  }
}
