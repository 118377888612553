import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationDialogComponent,
  NotificationService,
  Severity,
  TableComponent,
} from '@digital-factory/ds-common-ui';
import { TableConfiguration } from '@digital-factory/ds-common-ui/lib/info-model/table-configuration';
import { EMPTY, first, map, Observable, of } from 'rxjs';
import { StatusDisplay } from 'src/info-model/enums/status';
import { User, UsersService } from '../../services/users.service';
import { routePath } from '../app-routing';
import { BaseSubscriptionsDirective } from '../shared/base-subscriptions/base-subscriptions.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InstitutionsService, Tenant } from '../../services/institutions.service';
import { UserAddEditViewParams } from './user-add-edit-view/user-add-edit-view';
import { ApplicationsService } from '../../services/applications.service';
import { Application } from '../../info-model/application';
import { GenericRecord } from '../../info-model/generic';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent extends BaseSubscriptionsDirective implements OnInit {
  @ViewChild(TableComponent) table!: TableComponent;

  config: TableConfiguration = {
    name: 'UserManagement-UsersTable',
    pageSize: 10,
    dataSource: EMPTY,
    columns: [],
    actions: [],
    emptyMessage: 'No users match the search criteria',
  };

  isEmpty = true;
  isLoading = true;
  application!: Application;
  tenant!: Tenant;
  readonly routePath = routePath;

  private readonly applicationsService = inject(ApplicationsService);
  private readonly institutionsService = inject(InstitutionsService);
  private readonly usersService = inject(UsersService);
  private readonly dialog = inject(MatDialog);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  actionAddUser() {
    const { usersService, tenant } = this;
    const params: Partial<UserAddEditViewParams> = {
      mode: 'add',
    };
    this.router.navigate([...usersService.navigate(tenant.tenant_id, '0'), params]);
  }

  ngOnInit(): void {
    const { router, destroyRef, route, institutionsService, applicationsService } = this;

    applicationsService.initialize().subscribe(() => {
      route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((p) => {
        const tenantId = p['tenantId'];

        if (tenantId) {
          institutionsService.getTenant$(tenantId).subscribe((tenant: Tenant) => {
            this.tenant = tenant;
            this.application = applicationsService.get(tenant.application);
            this.initialize();
          });
        } else {
          router.navigate([routePath.error, '404']);
        }
      });
    });
  }

  delete(userEmail: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete User',
        message: `Are you sure you want to delete ${userEmail}?`,
        cancelButtonText: 'Cancel',
        okButtonText: 'Delete',
        okButtonId: 'Delete',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersService
          .deleteUser$(userEmail)
          .pipe(first())
          .subscribe(() => {
            this.refreshTable();
            this.notificationService.showMessage('The user was deleted.', Severity.Success);
          });
      }
    });
  }

  refreshTable() {
    this.table.selected = null;
    this.config = {
      ...this.config,
      dataSource: this.getData(true),
    };
  }

  private initialize() {
    const { router, usersService, tenant, applicationsService, application } = this;
    const toUser = (element: unknown) => element as User;

    this.config = {
      ...this.config,
      dataSource: this.getData(true),
      columns: [
        {
          key: 'user_name',
          label: 'E-mail',
          isSortable: true,
        },
        {
          key: 'first_name',
          label: 'Last Name',
        },
        {
          key: 'last_name',
          label: 'First Name',
        },
        {
          key: 'department',
          label: 'Department',
        },
        {
          key: 'role',
          label: 'Role',
          valueMap: applicationsService.kvsToRecord(application.user_roles),
        },
        {
          key: 'status',
          label: 'Status',
          valueMap: StatusDisplay,
        },
      ],
      actions: [
        {
          label: 'View User',
          icon: 'icon-eye',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              const params: Partial<UserAddEditViewParams> = {
                mode: 'view',
              };

              router.navigate([...usersService.navigate(tenant.tenant_id, toUser(element).user_name), params]);
            }
          },
        },
        {
          label: 'Update User',
          icon: 'icon-pencil',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              const params: Partial<UserAddEditViewParams> = {
                mode: 'edit',
              };

              router.navigate([...usersService.navigate(tenant.tenant_id, toUser(element).user_name), params]);
            }
          },
        },
        {
          label: 'Delete User',
          icon: 'icon-trash',
          isDisabled: (_) => false,
          callback: (element) => {
            this.delete((element as unknown as User).user_name);
          },
        },
      ],
    };
  }
  private mapUsersToRows = (users: User[]): GenericRecord[] => {
    this.isEmpty = users.length === 0;
    this.isLoading = false;
    return users as unknown as GenericRecord[];
  };

  private onError() {
    this.config = {
      ...this.config,
      dataSource: this.getData(false),
    };
  }

  private getData(shouldLoadData: boolean): Observable<GenericRecord[]> {
    if (shouldLoadData) {
      return this.usersService.getUsers$(this.tenant.tenant_id, () => this.onError()).pipe(map(this.mapUsersToRows));
    } else {
      this.isEmpty = true;
      this.isLoading = false;
      return of([]);
    }
  }
}
