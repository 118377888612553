import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HttpCallHandlerService } from '@digital-factory/ds-common-ui';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Link } from 'src/info-model/link';
import { UrlService } from './url.service';
import { Status } from 'src/info-model/enums/status';
import { Login } from 'src/info-model/enums/login';
import { Router } from '@angular/router';
import { routePath } from '../app/app-routing';

export interface Tenant {
  application: string;
  contact_email: string;
  customer_id: string;
  license_type: string;
  links: Link[];
  login_type: Login;
  phone: string;
  regulatory_entity: string;
  status: Status;
  support_email: string;
  tenant_id: string;
  tenant_name: string;
}

interface AllTenantsResult {
  tenants: Tenant[];
  links: Link[];
}

@Injectable()
export class InstitutionsService {
  private readonly httpClient = inject(HttpClient);
  private readonly httpCallHandlerService = inject(HttpCallHandlerService);
  private readonly urlService = inject(UrlService);
  private readonly router = inject(Router);

  private methodToHrefForTenants = new Map<string, string>();
  private tenantIdToLinksMap = new Map<string, Map<string, string>>();

  getTenants$(errHandler?: () => void): Observable<Tenant[]> {
    const result = this.httpClient.get<AllTenantsResult>(this.urlService.getApiUrl('tenants')).pipe(
      tap((res) => {
        this.methodToHrefForTenants = this.urlService.getLinksMap(res.links);
        res.tenants.forEach((tenant: Tenant) =>
          this.tenantIdToLinksMap.set(tenant.tenant_id!, this.urlService.getLinksMap(tenant.links)),
        );
      }),
      map((res) => res.tenants),
    );

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorHandler: () => {
        if (errHandler) {
          errHandler();
        }
        return 'Loading institutions failed.';
      },
    });
  }

  createTenant$(tenant: Tenant) {
    const url = this.methodToHrefForTenants.get('POST/new_tenant')?.toString() ?? '';
    const result = this.httpClient.post(url, JSON.stringify(tenant), {
      responseType: 'text',
    });

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorMessage: 'An error occurred. The institution could not be created.',
    });
  }

  getTenant$(tenantId: string): Observable<Tenant> {
    const url = `${this.urlService.getApiUrl('tenants')}/${tenantId}`;
    const result = this.httpClient.get<Tenant>(url);

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorHandler: () => {
        this.router.navigate([routePath.error, '400']);
        return `An error occurred. Institution not found: ${tenantId}`;
      },
    });
  }

  /**
   * Update a tenant. The URL is dependent on finding the appropriate PUT link inside the tenant
   *
   * @param tenant Tenant with a PUT link
   */
  updateTenant$(tenant: Tenant) {
    const url = tenant.links?.find((link) => link.type === 'PUT')?.href || ''; // tenant.links *MUST have an entry with type='PUT'

    this.urlService.validateUrl(
      url,
      'Update link for tenant ' + tenant.tenant_name + ' (' + tenant.tenant_id + ') is not valid',
    );

    const result = this.httpClient.put(url, JSON.stringify(tenant), { responseType: 'text' });
    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorMessage: 'An error occurred. The institution could not be updated.',
    });
  }

  deleteTenant$(tenantId: string, tenantName: string): Observable<string> {
    const url = this.tenantIdToLinksMap.get(tenantId)?.get('DELETE/self') ?? '';
    //User both tenant name and id in case tenantId is empty
    this.urlService.validateUrl(url, 'Delete link for tenant ' + tenantName + ' (' + tenantId + ') is not valid');

    const result = this.httpClient.delete(url, { responseType: 'text' });
    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorMessage: 'An error occurred. The institution could not be deleted.',
      errorHandlers: {
        409: () =>
          'The selected Institution could not be deleted because it has Users added to it. Please delete all the users first.',
      },
    });
  }
}
