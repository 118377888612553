import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HttpCallHandlerService } from '@digital-factory/ds-common-ui';
import { map, Observable, tap } from 'rxjs';

import { Status } from 'src/info-model/enums/status';
import { Link } from 'src/info-model/link';
import { UrlService } from './url.service';
import { routePath } from '../app/app-routing';
import { Router } from '@angular/router';

export interface User {
  department: string;
  first_name: string;
  last_name: string;
  links: Link[];
  role: string;
  status: Status;
  tenant_id: string;
  user_name: string;
}

interface AllUsersResult {
  users: User[];
  links: Link[];
}

@Injectable()
export class UsersService {
  methodToHrefForUsers = new Map<string, string>();
  userIdToLinksMap = new Map<string, Map<string, string>>();

  private readonly httpClient = inject(HttpClient);
  private readonly httpCallHandlerService = inject(HttpCallHandlerService);
  private readonly urlService = inject(UrlService);
  private readonly router = inject(Router);

  getUsers$(tenantId: string, errHandler?: () => void): Observable<User[]> {
    const { urlService, httpClient } = this;
    const url = urlService.getApiUrl(`tenants/${tenantId}/users`);
    const result = httpClient.get<AllUsersResult>(url).pipe(
      tap((res) => {
        this.methodToHrefForUsers = this.urlService.getLinksMap(res.links);
        res.users.forEach((user: User) =>
          this.userIdToLinksMap.set(user.user_name, this.urlService.getLinksMap(user.links)),
        );
      }),
      map((res) => res.users),
    );

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorHandler: () => {
        if (errHandler) {
          errHandler();
        }
        return 'Loading users failed.';
      },
    });
  }

  createUser$(user: Omit<User, 'links'>): Observable<string> {
    const { urlService, httpClient } = this;
    const url = urlService.getApiUrl(`tenants/${user.tenant_id}/users`);

    return httpClient.post(url, JSON.stringify(user), { responseType: 'text' });
  }

  /**
   * Get a user
   *
   * @param tenantId tenant ID
   * @param userId user ID
   * @param manageResponse if true, return the response - soft landing if you want to show a dupe
   * @return Observable<User>
   */
  getUser$(tenantId: string, userId: string, manageResponse = false): Observable<User> {
    const { urlService, router, httpCallHandlerService, httpClient } = this;
    const url = urlService.getApiUrl(`tenants/${tenantId}/users/${userId}`);
    const result = httpClient.get<User>(url);

    if (manageResponse) {
      return result;
    } else {
      return httpCallHandlerService.handleErrors(result, {
        defaultErrorHandler: () => {
          router.navigate([routePath.error, '400']);
          return `An error occurred. Institution not found: ${tenantId}`;
        },
      });
    }
  }

  updateUser$(user: User, manageResponse = false): Observable<string> {
    const { urlService, httpClient } = this;
    const url = user.links?.find((link) => link.type === 'PUT')?.href || ''; // user links *MUST have an entry with type='PUT'

    urlService.validateUrl(url, `Update link for ${user.user_name} is not valid`);

    const result = httpClient.put(url, JSON.stringify(user), { responseType: 'text' });

    if (manageResponse) {
      return result;
    } else {
      return this.httpCallHandlerService.handleErrors(result, {
        defaultErrorMessage: 'Your updates to the user record failed. Please try again.',
      });
    }
  }

  deleteUser$(userEmail: string) {
    const url = this.userIdToLinksMap.get(userEmail)?.get('DELETE/self') ?? '';
    this.urlService.validateUrl(url, 'Delete link for ' + userEmail + ' is not valid');

    const result = this.httpClient.delete(url, { responseType: 'text' });
    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorMessage: 'The user record was not deleted successfully. Please try again.',
    });
  }

  /**
   * Angular router.navigate routing
   *
   * @param tenantId tenant ID
   * @param userId user ID
   * @return /institutions/tenantId/users or /institutions/tenantId/users/userID
   */
  navigate(tenantId: string, userId?: string): string[] {
    const result = ['/', routePath.institutions, tenantId, routePath.users];

    if (userId) {
      result.push(userId);
    }

    return result;
  }
}
