import { Directive, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseSubscriptionsDirective } from '../base-subscriptions/base-subscriptions.directive';

import { BaseFormSuper } from './base-form.model';

/**
 * Not an Angular Component, but a base class for safe form building
 */
@Directive()
export class BaseFormDirective<FormModel extends object, FormValue = FormModel> extends BaseSubscriptionsDirective {
  readonly fb = inject(FormBuilder);
  form!: FormGroup;

  constructor(public bf: Partial<BaseFormSuper> = {}) {
    super();
  }

  private _resetValue!: FormValue;

  get resetValue(): FormValue {
    return this._resetValue;
  }

  set resetValue(value: FormValue) {
    this._resetValue = value;
    this.form.markAsPristine();
  }

  /**
   * @return typesafe form control
   */
  get controls(): Record<keyof FormModel, FormControl> {
    return this.form.controls as Record<keyof FormModel, FormControl>;
  }

  get controlsArray(): Record<keyof FormModel, FormArray> {
    return this.form.controls as Record<keyof FormModel, FormArray>;
  }

  /**
   * @return typesafe form.value, which will contain all the control values
   */
  get formValue(): FormValue {
    return this.form.value as FormValue;
  }

  /**
   * typesafe form creation
   */
  formCreate(model: FormModel): void {
    const { fb, bf } = this;
    const { nonNullable, allRequired } = bf;

    this.form = nonNullable ? fb.nonNullable.group(model) : fb.group(model);
    this.resetValue = this.formValue;

    if (allRequired) {
      const { controls } = this.form;

      Object.keys(controls).forEach((key: string) => {
        controls[key].addValidators(Validators.required);
      });
    }
  }

  formReset(): void {
    if (this.resetValue) {
      this.form.patchValue(this.resetValue);
    }
    this.form.markAsPristine();
  }

  /**
   * typesafe use of formControlName
   *
   * @param formControlName keyof FormModel
   * @return safe name
   */
  safeCol(formControlName: keyof FormModel): keyof FormModel {
    return formControlName;
  }
}
