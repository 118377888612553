@if (form) {
  <div class="institution-add-edit-view">
    <div class="heading">
      <h1 id="InstitutionAddEditView-Title">{{ title }}</h1>
      @if (mode !== 'add') {
        <div class="tenant-name" id="InstitutionAddEditView-TenantName">{{ tenant.tenant_name }}</div>
      }
    </div>
    <button
      mat-button
      color="primary"
      [routerLink]="['/', routePath.institutions, { tenant_name: tenant.tenant_name }]"
      id="InstitutionAddEditView-BackButton"
    >
      <span class="icon-arrow-left2"></span><span>&nbsp;Back to Institution List</span>
    </button>
    <div class="divider top">
      <mat-divider></mat-divider>
    </div>
    <form [formGroup]="form">
      <ng-container *ngTemplateOutlet="main_"></ng-container>
      <ng-container *ngTemplateOutlet="contact_"></ng-container>
      <ng-container *ngTemplateOutlet="language_"></ng-container>
      <ng-container *ngTemplateOutlet="application_"></ng-container>
      @if (mode !== 'view') {
        <ng-container *ngTemplateOutlet="buttons_"></ng-container>
      }
    </form>
  </div>
}

<ng-template #section_ let-sectionTitle>
  <div class="section-title">
    {{ sectionTitle }}
  </div>
  <div class="divider top">
    <mat-divider></mat-divider>
  </div>
</ng-template>

<ng-template #main_>
  <div class="section">
    <div>
      <label
        for="InstitutionAddEditView-InstitutionNameText"
        id="InstitutionAddEditView-InstitutionNameTextLabel"
        class="required"
        >Institution Name</label
      >
      <input
        class="field-input"
        [formControl]="controls.tenant_name"
        id="InstitutionAddEditView-InstitutionNameText"
        maxlength="25"
      />
      @if (hasError(controls.tenant_name, 'required') || hasError(controls.tenant_name, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidInstitutionNameLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-CustomerIdText"
        id="InstitutionAddEditView-CustomerIdTextLabel"
        >Customer ID</label
      >
      <input
        class="field-input"
        [formControl]="controls.customer_id"
        id="InstitutionAddEditView-CustomerIdText"
        maxlength="10"
      />
      @if (hasError(controls.customer_id, 'required') || hasError(controls.customer_id, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-CustomerIdRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-StatusOptions" id="InstitutionAddEditView-StatusOptionsLabel"
        >Status</label
      >
      <div id="InstitutionAddEditView-StatusOptions" class="flex-group">
        @for (key of statusDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              [value]="key"
              [formControl]="controls.status"
              [id]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              statusDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-LoginOptions" id="InstitutionAddEditView-LoginOptionsLabel"
        >Login</label
      >

      <div id="InstitutionAddEditView-LoginOptions" class="flex-group">
        @for (key of loginDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              [value]="key"
              [formControl]="controls.login_type"
              [id]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              loginDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
    @if (mode !== 'add') {
      <div>
        <label
          class="required"
          for="InstitutionAddEditView-GatewayKeyText"
          id="InstitutionAddEditView-GatewayKeyTextLabel"
          >Gateway Key</label
        >
        <input
          class="field-input"
          placeholder="(autogenerated after saving)"
          [formControl]="controls.tenant_id"
          id="InstitutionAddEditView-GatewayKeyText"
        />
      </div>
    }
  </div>
</ng-template>

<ng-template #contact_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Contact Info' }"> </ng-container>
  <div class="section">
    <div>
      <label class="required" for="InstitutionAddEditView-LastName" id="InstitutionAddEditView-LastNameLabel"
        >Last Name</label
      >
      <input class="field-input" value="Last Name (TBD)" id="InstitutionAddEditView-LastName" />
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-FirstName" id="InstitutionAddEditView-FirstNameLabel"
        >First Name</label
      >
      <input class="field-input" value="First Name (TBD)" id="InstitutionAddEditView-FirstName" />
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-EmailText" id="InstitutionAddEditView-EmailTextLabel"
        >Primary Contact</label
      >
      <input
        class="field-input"
        [formControl]="controls.contact_email"
        id="InstitutionAddEditView-EmailText"
        maxlength="128"
      />
      @if (hasError(controls.contact_email, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ContactEmailRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
      @if (hasError(controls.contact_email, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidEmailAddressFormatLabel">
          Invalid e-mail address format
        </div>
      }
    </div>
    <div>
      <label for="InstitutionAddEditView-PhoneText" id="InstitutionAddEditView-PhoneTextLabel">Phone</label>
      <input
        class="field-input w-12"
        [formControl]="controls.phone"
        id="InstitutionAddEditView-PhoneText"
        maxlength="16"
      />
      @if (hasError(controls.phone, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidPhoneNumberFormatLabel">
          Invalid Phone Number format
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #language_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Language and Format' }"> </ng-container>
  <div class="section">
    <label>Country</label>
    <label>Region</label>
    <label>Language</label>
    <label>Time Zone</label>
    <label>Time Format</label>
    <label>Number Format</label>
    <label>Date Format</label>
  </div>
</ng-template>

<ng-template #application_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Application' }"> </ng-container>
  <div class="section row-gap">
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-ApplicationSelect"
        id="InstitutionAddEditView-ApplicationSelectLabel"
        >Application</label
      >
      <select class="field-input" [formControl]="controls.application" id="InstitutionAddEditView-ApplicationSelect">
        @for (name of applicationsService.names; track name) {
          <option [value]="name.key">{{ name.value }}</option>
        }
      </select>
    </div>
    @if (application) {
      <div>
        <label
          class="required"
          for="InstitutionAddEditView-LicenseTypeSelect"
          id="InstitutionAddEditView-LicenseTypeSelectLabel"
          >Tiers</label
        >
        <select class="field-input" [formControl]="controls.license_type" id="InstitutionAddEditView-LicenseTypeSelect">
          @for (tier of application.tiers; track tier) {
            <option [value]="tier.key">{{ tier.value }}</option>
          }
        </select>
      </div>
      @if (application.options.length) {
        <div>
          <label for="InstitutionAddEditView-LicenseTypeOptions" id="InstitutionAddEditView-LicenseTypeOptionsLabel"
            >Options</label
          >
          <mat-select class="field-input" id="InstitutionAddEditView-LicenseTypeOptions" multiple>
            @for (option of application.options; track option) {
              <mat-option [value]="option.key">{{ option.value }}</mat-option>
            }
          </mat-select>
        </div>
      }
      @if (application.regulatory_entities.length) {
        <div>
          <label
            class="required"
            for="InstitutionAddEditView-RegulatoryEntitySelect"
            id="InstitutionAddEditView-RegulatoryEntitySelectLabel"
            >Regulatory Entity</label
          >
          <select
            class="field-input"
            [formControl]="controls.regulatory_entity"
            id="InstitutionAddEditView-RegulatoryEntitySelect"
          >
            @for (kv of application.regulatory_entities; track kv) {
              <option [value]="kv.key">{{ kv.value }}</option>
            }
          </select>
        </div>
      }
    }
    <div>
      <label for="InstitutionAddEditView-ItEmailText" id="InstitutionAddEditView-ItEmailTextLabel"
        >License Renewal Contact</label
      >
      <input
        class="field-input"
        [formControl]="controls.support_email"
        id="InstitutionAddEditView-ItEmailText"
        maxlength="128"
      />
      @if (hasError(controls.support_email, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidItEmailAddressFormatLabel">
          Invalid e-mail address format
        </div>
      }
    </div>
    <div>
      <label>License Expiration</label>
      <input class="field-input" value="2028-02-29 (TBD)" />
    </div>
  </div>
</ng-template>

<ng-template #buttons_>
  <div class="buttons">
    <button
      id="InstitutionAddEditView-SaveButton"
      class="save-button"
      mat-flat-button
      [disabled]="!(form.valid && form.dirty)"
      color="primary"
      (click)="actionSave()"
    >
      Save
    </button>
    <button
      id="InstitutionAddEditView-CancelButton"
      class="cancel-button"
      mat-stroked-button
      color="primary"
      [routerLink]="['/', routePath.institutions]"
    >
      Cancel
    </button>
  </div>
</ng-template>
