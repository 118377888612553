import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, AuthorizationService, NotificationService } from '@digital-factory/ds-common-ui';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(
    snackBar: MatSnackBar,
    notificationService: NotificationService,
    authenticationService: AuthenticationService,
    authorizationService: AuthorizationService,
  ) {
    notificationService.setSnackBar(snackBar);

    const urlPrefix = environment.urlPrefix === 'prod' ? '' : `${environment.urlPrefix}.`;
    const cognitoApi = `https://auth.${urlPrefix}werfencloud.com`;
    const landingPage = `https://${urlPrefix}werfencloud.com`;
    authenticationService.initialize(cognitoApi, environment.cognitoAppClientId, landingPage);

    const authorizationApi = `https://api.${urlPrefix}werfencloud.com/stratus-platform-management/v1/authenticate`;
    authorizationService.initialize(authorizationApi);
  }
}
