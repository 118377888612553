@if (form) {
  <div class="institution-add-edit-view">
    <div class="heading">
      <h1 id="InstitutionAddEditView-Title">{{ title }}</h1>
      @if (mode !== 'add') {
        <div class="tenant-name" id="InstitutionAddEditView-TenantName">{{ myForm.tenant_name }}</div>
      }
    </div>
    <button
      mat-button
      color="primary"
      [routerLink]="['/', routePath.institutions, { tenant_name: myForm.tenant_name }]"
      id="InstitutionAddEditView-BackButton"
    >
      <span class="icon-arrow-left2"></span><span>&nbsp;Back to Institution List</span>
    </button>
    <div class="divider top">
      <mat-divider></mat-divider>
    </div>
    <form [formGroup]="form">
      <ng-container *ngTemplateOutlet="main_"></ng-container>
      <ng-container *ngTemplateOutlet="gateway_"></ng-container>
      <ng-container *ngTemplateOutlet="contact_"></ng-container>
      <ng-container *ngTemplateOutlet="language_"></ng-container>
      <ng-container *ngTemplateOutlet="application_"></ng-container>
      @if (mode !== 'view') {
        <ng-container *ngTemplateOutlet="buttons_"></ng-container>
      }
    </form>
  </div>
}

<ng-template #section_ let-sectionTitle>
  <div class="section-title">
    {{ sectionTitle }}
  </div>
  <div class="divider top">
    <mat-divider></mat-divider>
  </div>
</ng-template>

<ng-template #main_>
  <div class="section">
    <div>
      <label
        for="InstitutionAddEditView-InstitutionNameText"
        id="InstitutionAddEditView-InstitutionNameTextLabel"
        class="required"
        >Institution Name</label
      >
      <input
        class="field-input"
        [formControl]="controls.tenant_name"
        id="InstitutionAddEditView-InstitutionNameText"
        maxlength="25"
      />
      @if (hasError(controls.tenant_name, 'required') || hasError(controls.tenant_name, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidInstitutionNameLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-CustomerIdText"
        id="InstitutionAddEditView-CustomerIdTextLabel"
        >Customer ID</label
      >
      <input
        class="field-input"
        [formControl]="controls.customer_id"
        id="InstitutionAddEditView-CustomerIdText"
        maxlength="10"
      />
      @if (hasError(controls.customer_id, 'required') || hasError(controls.customer_id, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-CustomerIdRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-StatusOptions" id="InstitutionAddEditView-StatusOptionsLabel"
        >Status</label
      >
      <div id="InstitutionAddEditView-StatusOptions" class="flex-group">
        @for (key of statusDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              name="InstitutionAddEditView-StatusOptionsName"
              [value]="key"
              [formControl]="controls.status"
              [id]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              statusDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-LoginOptions" id="InstitutionAddEditView-LoginOptionsLabel"
        >Login</label
      >
      <div id="InstitutionAddEditView-LoginOptions" class="flex-group">
        @for (key of loginDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              name="InstitutionAddEditView-LoginOptionsName"
              [value]="key"
              [formControl]="controls.login_type"
              [id]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'InstitutionEditor-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              loginDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
    <div>
      <label for="InstitutionAddEditView-CountryInput" id="InstitutionAddEditView-CountryInputLabel">Country</label>
      <input
        class="field-input"
        [formControl]="controls.country"
        id="InstitutionAddEditView-CountryInput"
        maxlength="64"
      />
    </div>
    <div>
      <label for="InstitutionAddEditView-RegionInput" id="InstitutionAddEditView-RegionInputLabel">Region</label>
      <input
        class="field-input"
        [formControl]="controls.region"
        id="InstitutionAddEditView-RegionInput"
        maxlength="64"
      />
    </div>
    <div>
      <label for="InstitutionAddEditView-LanguageInput" id="InstitutionAddEditView-LanguageInputLabel">Language</label>
      <select class="field-input" [formControl]="controls.language" id="InstitutionAddEditView-LanguageInput">
        @for (kv of tenantLanguages; track kv) {
          <option [value]="kv.key">{{ kv.value }}</option>
        }
      </select>
    </div>
    <div>
      <label for="InstitutionAddEditView-TimeZoneInput" id="InstitutionAddEditView-TimeZoneInputLabel">Time Zone</label>
      <input
        class="field-input"
        [formControl]="controls.time_zone"
        id="InstitutionAddEditView-TimeZoneInput"
        maxlength="64"
      />
    </div>
  </div>
</ng-template>

<ng-template #contact_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Contact Info' }"> </ng-container>
  <div class="section">
    <div>
      <label class="required" for="InstitutionAddEditView-LastName" id="InstitutionAddEditView-LastNameLabel"
        >Last Name</label
      >
      <input
        class="field-input"
        [formControl]="controls.contact_lastname"
        id="InstitutionAddEditView-LastName"
        maxlength="128"
      />
      @if (hasError(controls.contact_lastname, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ContactLastNameRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
      @if (hasError(controls.contact_lastname, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidLastNameAddressFormatLabel">
          Invalid last name format
        </div>
      }
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-FirstName" id="InstitutionAddEditView-FirstNameLabel"
        >First Name</label
      >
      <input
        class="field-input"
        [formControl]="controls.contact_firstname"
        id="InstitutionAddEditView-FirstName"
        maxlength="128"
      />
      @if (hasError(controls.contact_firstname, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ContactFirstNameRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
      @if (hasError(controls.contact_firstname, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidFirstNameAddressFormatLabel">
          Invalid first name format
        </div>
      }
    </div>
    <div>
      <label class="required" for="InstitutionAddEditView-EmailText" id="InstitutionAddEditView-EmailTextLabel"
        >Primary Contact</label
      >
      <input
        class="field-input"
        [formControl]="controls.contact_email"
        id="InstitutionAddEditView-EmailText"
        maxlength="128"
      />
      @if (hasError(controls.contact_email, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ContactEmailRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
      @if (hasError(controls.contact_email, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidEmailAddressFormatLabel">
          Invalid e-mail address format
        </div>
      }
    </div>
    <div>
      <label for="InstitutionAddEditView-PhoneText" id="InstitutionAddEditView-PhoneTextLabel">Phone</label>
      <input
        class="field-input"
        [formControl]="controls.contact_phone"
        id="InstitutionAddEditView-PhoneText"
        maxlength="16"
      />
      @if (hasError(controls.contact_phone, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidPhoneNumberFormatLabel">
          Invalid Phone Number format
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #language_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Application Formatting' }"> </ng-container>
  <div class="section">
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-TimeFormatInput"
        id="InstitutionAddEditView-TimeFormatInputLabel"
        >Time Format</label
      >
      <select class="field-input" [formControl]="controls.time_format" id="InstitutionAddEditView-TimeFormatInput">
        @for (kv of tenantTimeFormats; track kv) {
          <option [value]="kv.key">{{ kv.value }}</option>
        }
      </select>
      @if (hasError(controls.time_format, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-TimeFormatInputRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-NumberFormatInput"
        id="InstitutionAddEditView-NumberFormatInputLabel"
        >Number Format</label
      >
      <select class="field-input" [formControl]="controls.number_format" id="InstitutionAddEditView-NumberFormatInput">
        @for (kv of tenantNumberFormats; track kv) {
          <option [value]="kv.key">{{ kv.value }}</option>
        }
      </select>
      @if (hasError(controls.number_format, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-NumberFormatInputRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-DateFormatInput"
        id="InstitutionAddEditView-DateFormatInputLabel"
        >Date Format</label
      >
      <select class="field-input" [formControl]="controls.date_format" id="InstitutionAddEditView-DateFormatInput">
        @for (key of tenantDateFormats; track key) {
          <option [value]="key">{{ key }}</option>
        }
      </select>
      @if (hasError(controls.date_format, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-DateFormatInputRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #application_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Application' }"> </ng-container>
  <div class="section row-gap">
    <div>
      <label
        class="required"
        for="InstitutionAddEditView-ApplicationSelect"
        id="InstitutionAddEditView-ApplicationSelectLabel"
        >Application</label
      >
      <select class="field-input" [formControl]="controls.application" id="InstitutionAddEditView-ApplicationSelect">
        @for (kv of applicationsService.names; track kv) {
          <option [value]="kv.key">{{ kv.value }}</option>
        }
      </select>
      @if (hasError(controls.application, 'required')) {
        <div class="error-text" id="InstitutionAddEditView-ApplicationSelectRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    @if (application) {
      <div>
        <label
          class="required"
          for="InstitutionAddEditView-LicenseTypeSelect"
          id="InstitutionAddEditView-LicenseTypeSelectLabel"
          >Tier</label
        >
        <select class="field-input" [formControl]="controls.tier" id="InstitutionAddEditView-LicenseTypeSelect">
          @for (kv of application.tiers; track kv) {
            <option [value]="kv.key">{{ kv.value }}</option>
          }
        </select>
        @if (hasError(controls.tier, 'required')) {
          <div class="error-text" id="InstitutionAddEditViewLicenseTypeSelectRequiredFieldLabel">
            {{ error.required }}
          </div>
        }
      </div>
      @if (application.options.length) {
        <div>
          <label for="InstitutionAddEditView-LicenseTypeOptions" id="InstitutionAddEditView-LicenseTypeOptionsLabel"
            >Options</label
          >
          <mat-select
            class="field-input"
            [formControl]="controls.license_options"
            multiple
            id="InstitutionAddEditView-LicenseTypeOptions"
          >
            @for (kv of application.options; track kv) {
              <mat-option [value]="kv.key">{{ kv.value }}</mat-option>
            }
          </mat-select>
        </div>
      }
      @if (application.regulatory_entities.length) {
        <div>
          <label
            class="required"
            for="InstitutionAddEditView-RegulatoryEntitySelect"
            id="InstitutionAddEditView-RegulatoryEntitySelectLabel"
            >Regulatory Entity</label
          >
          <select
            class="field-input"
            [formControl]="controls.regulatory_entity"
            id="InstitutionAddEditView-RegulatoryEntitySelect"
          >
            @for (kv of application.regulatory_entities; track kv) {
              <option [value]="kv.key">{{ kv.value }}</option>
            }
          </select>
          @if (hasError(controls.regulatory_entity, 'required')) {
            <div class="error-text" id="InstitutionAddEditViewLRegulatoryEntitySelectRequiredFieldLabel">
              {{ error.required }}
            </div>
          }
        </div>
      }
    }
    <div>
      <label for="InstitutionAddEditView-LicenseRenewalInput" id="InstitutionAddEditView-LicenseRenewalInputLabel"
        >License Renewal Contact E-mail</label
      >
      <input
        class="field-input"
        [formControl]="controls.license_renewal_contact"
        id="InstitutionAddEditView-LicenseRenewalInput"
      />
      @if (hasError(controls.license_renewal_contact, 'pattern')) {
        <div class="error-text" id="InstitutionAddEditView-InvalidLicenseRenewalInput">
          Invalid e-mail address format
        </div>
      }
    </div>
    <div>
      <label
        for="InstitutionAddEditView-LicenseExpirationInput"
        id="InstitutionAddEditView-LicenseExpirationInputLabel"
        class="required"
        >License Expiration</label
      >
      <input
        class="field-input"
        type="date"
        [formControl]="controls.license_expiration_date"
        id="InstitutionAddEditView-LicenseExpirationInput"
      />
      @if (hasError(controls.license_expiration_date, 'required')) {
        <div class="error-text" id="InstitutionAddEditViewLLicenseExpirationInputRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #gateway_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: 'Gateway / Installation Information' }">
  </ng-container>
  <div class="section">
    <div>
      <label for="InstitutionAddEditView-GatewayKeyText" id="InstitutionAddEditView-GatewayKeyTextLabel"
        >Gateway Key</label
      >
      <div class="flex-group">
        <input
          class="field-input"
          placeholder="Viewable after save"
          [formControl]="controls.gateway_key"
          id="InstitutionAddEditView-GatewayKeyText"
        />
        @if (controls.gateway_key.value) {
          <button mat-icon-button color="primary" (click)="actionClipboard('gateway_key')" [matTooltip]="copyTooltip">
            <span class="icon-copy"></span>
          </button>
        }
      </div>
    </div>
    <div>
      <label for="InstitutionAddEditView-ClientIdText" id="InstitutionAddEditView-ClientIdTextLabel">Client ID</label>
      <div class="flex-group">
        <input
          class="field-input"
          [formControl]="controls.client_id"
          id="InstitutionAddEditView-ClientIdText"
          placeholder="Viewable after save"
        />
        @if (controls.client_id.value) {
          <button mat-icon-button color="primary" (click)="actionClipboard('client_id')" [matTooltip]="copyTooltip">
            <span class="icon-copy"></span>
          </button>
        }
      </div>
    </div>
    <div>
      <label for="InstitutionAddEditView-ClientSecretText" id="InstitutionAddEditView-ClientSecretTextLabel"
        ><span>Client Secret</span>
        @if (controls.client_secret.value) {
          <span
            id="InstitutionAddEditView-ClientSecretIconEye"
            class="icon-eye"
            matTooltip="Click to toggle visibility"
            (click)="clientSecretVisible = !clientSecretVisible"
          ></span>
        }
      </label>
      <div class="flex-group">
        <input
          [type]="clientSecretVisible ? 'text' : 'password'"
          class="field-input"
          placeholder="Viewable after save"
          [formControl]="controls.client_secret"
          id="InstitutionAddEditView-ClientSecretText"
        />
        @if (controls.client_secret.value) {
          <button mat-icon-button color="primary" (click)="actionClipboard('client_secret')" [matTooltip]="copyTooltip">
            <span class="icon-copy"></span>
          </button>
        }
      </div>
    </div>
  </div>
</ng-template>
<ng-template #buttons_>
  <div class="buttons">
    <button
      id="InstitutionAddEditView-SaveButton"
      class="save-button"
      mat-flat-button
      [disabled]="!(form.dirty && form.valid)"
      color="primary"
      (click)="actionSave()"
    >
      Save
    </button>
    <button
      id="InstitutionAddEditView-CancelButton"
      class="cancel-button"
      mat-stroked-button
      color="primary"
      [routerLink]="['/', routePath.institutions]"
    >
      Cancel
    </button>
  </div>
</ng-template>
