import { KeyValue } from '@angular/common';

export interface Application {
  id: number;
  multiple_locations: boolean;
  name: KeyValue<string, string>;
  options: KeyValue<string, string>[];
  regulatory_entities: KeyValue<string, string>[];
  tiers: KeyValue<string, string>[];
  user_roles: KeyValue<string, string>[];
}

/** .../v1/appconfig */
export interface ServerApplication {
  id: number;
  multiple_locations: boolean;
  name: Record<string, string>;
  options: Record<string, string>;
  options_order: string[];
  regulatory_entities: Record<string, string>;
  regulatory_entities_order: string[];
  tiers: Record<string, string>;
  tiers_order: string[];
  user_roles: Record<string, string>;
  user_roles_order: string[];
}

export const applicationRoleWerfenAdmin = 'WERFEN_CLOUD_ADMINISTRATOR';
